<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap6BinaryAndDecimal"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 6: Binary and Decimal" image-name="binary.jpg" image-alt="Binary and Decimal"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>The difference between a decimal and a binary numeration system.</p></li>
<li><p>How computers store data.</p></li>
<li><p>What is the difference between 32 bits and 64 bits systems?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h2 data-number="1.1"><span class="header-section-number">1.1</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>Binary</p></li>
<li><p>Bits, Bytes</p></li>
<li><p>Decimal</p></li>
<li><p>Encoding, Encoder</p></li>
<li><p>RAM</p></li>
</ul>
<div id="introduction-numerals-numbers-and-quantity" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Introduction: Numerals, numbers, and quantity <a href="#introduction-numerals-numbers-and-quantity"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Let’s define some important words :</p>
<ul>
<li><p><strong>Numeral</strong> : a symbol or a group of characters that represent a number<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a></p></li>
<li><p>A <strong>number</strong> represent a quantity, something that we can count.</p></li>
<li><p>A <strong>numeral</strong> is a representation of a <strong>number.</strong></p></li>
<li><p>A <strong>numeration system</strong> is a set of rules that allow us to count things and to represent quantities.</p></li>
</ul>
<p>Every day we manipulate numbers represented with the decimal system: 10.254 , 125, 2020, 31 .…</p>
<p>But <strong>the decimal system is not the only one</strong> and was not always used.</p>
<p>We can represent numbers using other numeration systems.</p>
<ul>
<li><p>Let’s take as an example the quantity “one hundred twenty-three”</p>
<p>“123” represent this quantity in the decimal system</p>
<ul>
<li>The numeral is “123”</li>
</ul>
<p>“1111011” represent this quantity in the binary system</p>
<ul>
<li>The numeral is “1111011”</li>
</ul>
<p>“7B” represent this quantity in the hexadecimal system</p>
<ul>
<li>The numeral is “7B”</li>
</ul>
<p>“173” represent this quantity in the octal system</p>
<ul>
<li>The numeral is “173”</li>
</ul></li>
</ul>
<p>The same quantity, the same underlying reality can be expressed in different forms.</p>
<p>Why is it important to understand that? Because computers will not store data using the decimal system. At the physical level, information is stored using zeros and ones.</p>
<p>This chapter will detail how the decimal system and the binary system work.</p>
<div id="etymology-and-symbols" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Etymology and symbols <a href="#etymology-and-symbols"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Decimal comes from the Latin word “<strong>Decimus</strong>” which means “tenth”. Whereas binary comes from the Latin “<strong>bini</strong>” which means “two together”. The etymology of those two words give us a hint about how those systems are constructed :</p>
<ul>
<li><p>The binary system uses two symbols which are 0 and 1</p></li>
<li><p>The decimal system uses ten symbols which are 0, 1, 2, 3, 4, 5, 6, 7, 8, 9.</p></li>
</ul>
<p>Data written using the binary system will be written using “0” and “1”. For instance, 101010 is written using the binary encoding system.</p>
<p>Data written using the decimal system will be written using 0, 1, 2, 3, 4, 5, 6, 7, 8, 9. For instance, 42 is written using the decimal system. The number 10 can be encoded using the binary system or the decimal system (and thus, it will not represent the same underlying quantity).</p>
<div id="the-decimal-system" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> The decimal system <a href="#the-decimal-system"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Let’s take a look at a number written using the decimal system : <span class="math display">123</span></p>
<p>This number is one hundred twenty-three. This number is composed of “digits”. The first digit represents the number of hundreds. The second digit is the number of tens. The last digit is the number of ones. The decimal system is <strong>positional</strong>. This means that the contribution of a digit to the number depends on the digit’s position in the number.</p>
<figure>
<b-img :src="require('@/assets/images/decimal_123.png')" alt="The decimal system is positional[fig:decimal-position-123-1]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The decimal system is positional<span id="fig:decimal-position-123-1" label="fig:decimal-position-123-1">[fig:decimal-position-123-1]</span></figcaption>
</figure>
<p>Let’s take a step further. We can write the number one hundred with the number ten : <span class="math display">100=10\times10</span></p>
<p>We can use <strong>powers</strong> of ten : <span class="math display">10\times10=10^{2}</span></p>
<p><span class="math inline">10^{2}</span> is the equivalent of <span class="math inline">10\times10</span>. We say that <span class="math inline">10</span> is the <strong>base</strong> and <span class="math inline">2</span> is the the <strong>power</strong>. When we read it, we say ten raised to the power of 2. Following the same logic, we can write : <span class="math display">10=10^{1}</span></p>
<p>And : <span class="math display">1=10^{0}</span></p>
<p>The last one may seem strange. This is a mathematical rule: any non-zero number raised to 0 is equal to 1.</p>
<dl>
<dt>With that in mind, we can decompose the number <span class="math inline">123</span> with powers of ten</dt>
<dd><span class="math display">123=1\times10^{2}+2\times10^{1}+3\times10^{0}</span>
</dd>
</dl>
<p>If you read <span class="math inline">1\times10^{2}+2\times10^{1}+3\times10^{0}</span> from left to right, you can note that the power of 10 is decreasing one by one.</p>
<p>This power often correspond to the position of the digit when the number is written down.</p>
<ul>
<li><p>the digit “1” is at the position 2 <span class="math inline">(1\times10^{2})</span></p></li>
<li><p>the digit “2” is at the position 1 <span class="math inline">(2\times10^{1})</span></p></li>
<li><p>the digit “3” is at the position 0 <span class="math inline">(3\times10^{0})</span></p></li>
</ul>
<p>The way I counted the positions might be surprising to you. You expected maybe something like that :</p>
<ul>
<li><p>the digit “1” is at position 3</p></li>
<li><p>the digit “2” is at position 2</p></li>
<li><p>the digit “3” is at position 1</p></li>
</ul>
<p>This is entirely correct if you start the count with 1, but we start the count with zero. Remember this convention because it will help you later!</p>
<figure>
<b-img :src="require('@/assets/images/base10_positioning.png')" alt="The relation between decimal digits and powers of ten"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The relation between decimal digits and powers of ten</figcaption>
</figure>
<p>In conclusion, the relation between the numeral and the number is the following. :</p>
<p>For a given numeral : <span class="math display">\text{digit}_{2}\text{digit}_{1}\text{digit}_{0}</span></p>
<p>The corresponding number (quantity) is : <span class="math display">\text{digit}_{2}\times10^{2}+\text{digit}_{1}\times10^{1}+\text{digit}_{0}\times10^{0}</span></p>
<p>Of course, this relation is valid for numerals with more (and less) than Three digits!</p>
<div id="fractional-numbers" class="anchor"></div>
<h2 data-number="4.1"><span class="header-section-number">4.1</span> Fractional numbers <a href="#fractional-numbers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>We have seen how the system works for round quantities; what about fractional numbers (numbers with a “decimal separator”, like <span class="math inline">123.14</span>)</p>
<p>There is still a relationship with the power of tens. <span class="math display">123.45=1\times10^{2}+2\times10^{1}+3\times10^{0}+4\times\frac{1}{10^{1}}+5\times\frac{1}{10^{2}}</span></p>
<p>This makes sense because : <span class="math inline">\frac{1}{10^{1}}=\frac{1}{10}=0.1</span></p>
<ul>
<li>So , <span class="math inline">4\times\frac{1}{10^{1}}=0.4</span></li>
</ul>
<p><span class="math inline">\frac{1}{10^{2}}=\frac{1}{100}=0.01</span></p>
<ul>
<li>So , <span class="math inline">5\times\frac{1}{10^{2}}=0.05</span></li>
</ul>
<div id="the-binary-system" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> The binary system <a href="#the-binary-system"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A number written in binary is composed of zeros and ones. The binary system, like the decimal system, is a <strong>positional numeral system</strong>. It means that each digit has a value that depends on its position. It’s a base two system (the decimal system is base 10).</p>
<p>Let’s take a look at a binary number : <span class="math display">10_{binary}</span></p>
<p>Note that I added the subscript “binary” to the number because this number also exists in the decimal system. The decimal number 10 is NOT equal to the binary number 10. It does not express the same quantity. <span class="math inline">10_{binary}</span> is a numeral composed of two binary digits. The term <strong>bi</strong>nary digi<strong>t</strong> has a widely used abbreviation: <strong>bit</strong>. We can get the decimal equivalent of this number by using powers of two : <span class="math display">10_{binary}=(1\times2^{1}+0\times2^{0})_{decimal}</span></p>
<p><span class="math display">10_{binary}=(1\times2)_{decimal}</span></p>
<p><span class="math display">10_{binary}=2_{decimal}</span></p>
<p>The binary number <span class="math inline">10_{binary}</span> represents the same quantity than <span class="math inline">2_{decimal}</span>. It is two units. Let’s take another example. <span class="math display">100010_{binary}</span></p>
<p>We will find its decimal representation. We take each digit; we multiply it by <span class="math inline">2^{x}</span> where <span class="math inline">x</span> is the position of the digit in the binary number (see figure <a href="#fig:Binary-to-decimal-1" data-reference-type="ref" data-reference="fig:Binary-to-decimal-1">2</a>)</p>
<figure>
<b-img :src="require('@/assets/images/binary_to_decimal.png')" alt="Binary to decimal conversion[fig:Binary-to-decimal-1]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Binary to decimal conversion<span id="fig:Binary-to-decimal-1" label="fig:Binary-to-decimal-1">[fig:Binary-to-decimal-1]</span></figcaption>
</figure>
<figure>
<b-img :src="require('@/assets/images/base2_positioning.png')" alt="Base 2 positioning"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Base 2 positioning</figcaption>
</figure>
<p>By the way, there is a famous joke that I heard once from a colleague about this: “There are ten types of people in the world, the ones that understand binary and the others” :)</p>
<div id="storage-capacity" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Storage capacity <a href="#storage-capacity"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="the-storage-capacity-of-2-bits" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> The storage capacity of 2 bits <a href="#the-storage-capacity-of-2-bits"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>What is the maximum decimal number we can store in a two binary digit number? Here is the list of the binary numbers that are composed of 2 digits : <span class="math inline">00_{binary}=(0\times2^{1}+0\times2^{0})_{decimal}=0_{decimal}</span></p>
<p><span class="math inline">01_{binary}=(0\times2^{1}+1\times2^{0})_{decimal}=1_{decimal}</span></p>
<p><span class="math inline">10_{binary}=(1\times2^{1}+0\times2^{0})_{decimal}=2_{decimal}</span></p>
<p><span class="math inline">11_{binary}=(1\times2^{1}+1\times2^{0})_{decimal}=3_{decimal}</span> With two binary digits, we can store the numbers <span class="math inline">0_{decimal}</span> to <span class="math inline">3_{decimal}</span>. The maximum number that can be stored is <span class="math inline">3_{decimal}</span></p>
<div id="the-storage-capacity-of-3-bits" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> The storage capacity of 3 bits <a href="#the-storage-capacity-of-3-bits"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>What is the maximum decimal number we can store in a three binary digit number? Here is the list of the binary numbers that are composed of 2 digits : <span class="math inline">000_{binary}=(0\times2^{2}+0\times2^{1}+0\times2^{0})_{decimal}=0_{decimal}</span></p>
<p><span class="math inline">001_{binary}=(0\times2^{2}+0\times2^{1}+1\times2^{0})_{decimal}=1_{decimal}</span></p>
<p><span class="math inline">010_{binary}=(0\times2^{2}+1\times2^{1}+0\times2^{0})_{decimal}=2_{decimal}</span></p>
<p><span class="math inline">011_{binary}=(0\times2^{2}+1\times2^{1}+1\times2^{0})_{decimal}=3_{decimal}</span></p>
<p><span class="math inline">100_{binary}=(1\times2^{2}+0\times2^{1}+0\times2^{0})_{decimal}=4_{decimal}</span></p>
<p><span class="math inline">101_{binary}=(1\times2^{2}+0\times2^{1}+1\times2^{0})_{decimal}=5_{decimal}</span></p>
<p><span class="math inline">110_{binary}=(1\times2^{2}+1\times2^{1}+0\times2^{0})_{decimal}=6_{decimal}</span></p>
<p><span class="math inline">111_{binary}=(1\times2^{2}+1\times2^{1}+1\times2^{0})_{decimal}=7_{decimal}</span> We can store all the numbers between <span class="math inline">0_{decimal}</span> and <span class="math inline">7_{decimal}</span> and the maximum decimal number that can be stored on 3 bits is <span class="math inline">7_{decimal}</span>.</p>
<div id="the-storage-capacity-of-8-bits-one-byte" class="anchor"></div>
<h2 data-number="6.3"><span class="header-section-number">6.3</span> The storage capacity of 8 bits (one byte) <a href="#the-storage-capacity-of-8-bits-one-byte"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>What is the maximum decimal number we can store in an eight binary digit number? We could list all the different binary numbers that can be built, but it would be time-consuming. Did you notice in the previous sections that the maximum number is made with only ones? It seems logical when a binary number is composed of just ones; its decimal value will be equal to the sum of powers of two (from 0 to <span class="math inline">n</span>) where n is the number of digits minus 1. If there is a single 0 digit in the binary number, the power of two corresponding is not counted. <span class="math display">11111111_{binary}=(1\times2^{7}+1\times2^{6}\times1\times2^{5}+1\times2^{4}+1\times2^{3}+1\times2^{2}+1\times2^{1}+1\times2^{0})_{decimal}</span></p>
<p><span class="math display">=(1\times2^{7}+1\times2^{6}\times1\times2^{5}+1\times2^{4}+1\times2^{3}+1\times2^{2}+1\times2^{1}+1\times2^{0})_{decimal}</span></p>
<p><span class="math display">=255_{decimal}</span></p>
<p>With 8 bits, we can store all the numbers between <span class="math inline">0_{decimal}</span> and <span class="math inline">255_{decimal}</span>.<strong>8 bits</strong> is called a <strong>byte</strong>. From <span class="math inline">0_{decimal}</span> to <span class="math inline">255_{decimal}</span> there are 256 numbers.</p>
<div id="how-to-store-images-videos..." class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> How to store images, videos,... ? <a href="#how-to-store-images-videos..."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We detailed how to transform a decimal number into a binary number to store it in memory. I hope it was clear and exciting. It’s therefore not sufficient. Programs manipulate far more things than numbers.</p>
<p>We can build programs that use :</p>
<ul>
<li><p>Images</p></li>
<li><p>Texts</p></li>
<li><p>Movies</p></li>
<li><p>3D models</p></li>
<li><p>.….</p></li>
</ul>
<p>How are those objects stored in memory?</p>
<p>The answer is simple: at the end, even photos and movies will be stored using zeros and ones! We will convert them into binary. This job will be performed by specialized programs called <strong>encoders</strong>. An encoder will take as input a file from a specific format and will convert it into a destination format. In our case, the destination is binary.</p>
<p>We do not need to write those programs; they are all provided by Go. You need to understand that every file or chunk of data is stored under the hood using binary digits (bits).</p>
<p>The binary representation is just hidden from us.</p>
<div id="vs.-64-bits-systems" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> 32 vs. 64 bits systems <a href="#vs.-64-bits-systems"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="programs-need-memory" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> Programs need memory <a href="#programs-need-memory"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The processor of a computer is responsible for executing programs. Most programs need to store and access memory. If you write a program that will display “42” on the screen. Behind the scene, the number 42 needs to be stored somewhere. The system will also need to fetch it from memory.</p>
<p>This is done via an addressing system. Each bit of information is stored in a precise location in the memory unit And to get them, the processor needs to have the ability to get its full address.</p>
<p>Remember that there are two types of memory :</p>
<ul>
<li><p>The central memory: ROM and RAM</p></li>
<li><p>The auxiliary memory: Hard drive, USB keys...</p></li>
</ul>
<p>Here we consider only the central memory.</p>
<div id="a-memory-address-is-a-number" class="anchor"></div>
<h2 data-number="8.2"><span class="header-section-number">8.2</span> A memory address is a number <a href="#a-memory-address-is-a-number"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>For a processor <strong>an address is a number</strong>. A memory address is like a postal address. It identifies precisely a point in memory space.</p>
<p>Processors will store addresses on registers. A register is a place inside the processor where an address can be saved for later use. For instance, let say that we have a program that makes an addition. Our program defines a variable that will hold the first value (let say 1.234) and another one that will store the second value (let say 1.290.999). When the processor executes our program, it will need to fetch the value 1.234 from memory and the value 1.290.999. The processor will save the two addresses on its registers.</p>
<div id="memory-addressable-a-limited-resource" class="anchor"></div>
<h2 data-number="8.3"><span class="header-section-number">8.3</span> Memory addressable: a limited resource <a href="#memory-addressable-a-limited-resource"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Registers have a limited capacity; they can store addresses of a specific size (written in bits). For a 16 bit processor, the maximum capacity of those registers is... 16 bits. For 32 bits processors, the maximum capacity is 32 bits. The same reasoning applies to 64 bits.</p>
<p>The maximum register capacity will define how much memory we can address.</p>
<p>Why? Remember that we store addresses on those registers and an address is a number:</p>
<ul>
<li><p><strong>On 32 bits</strong> we can store numbers from 0 to <span class="math inline">4.294.967.295\approx2^{32}</span>.</p>
<ul>
<li>With 32 bits, we can store 4 billion unsigned numbers, 4 billion addresses.</li>
</ul></li>
<li><p><strong>On 64 bits</strong> we can store numbers from 0 to <span class="math inline">18.446.744.073.709.551.615</span><span class="math inline">(\approx2^{64})</span>.</p>
<ul>
<li>That makes a lot of addresses possible</li>
</ul></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/32_bits_addressable.png')" alt="32 bits = 4 billion addresses"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">32 bits = 4 billion addresses</figcaption>
</figure>
<figure>
<b-img :src="require('@/assets/images/64_bits_addressable.png')" alt="64 bits = 18.446.744.073 billion addresses (a lot of addresses :))"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">64 bits = 18.446.744.073 billion addresses (a lot of addresses :))</figcaption>
</figure>
<div id="the-relation-between-the-number-of-addresses-possible-and-ram-size" class="anchor"></div>
<h2 data-number="8.4"><span class="header-section-number">8.4</span> The relation between the number of addresses possible and RAM size <a href="#the-relation-between-the-number-of-addresses-possible-and-ram-size"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>RAM is a hardware component composed of memory cells. Bits are stored in cells. In general, RAM is said to be byte-addressable. It means that the system can fetch data 8 bits at a time.</p>
<p>We have seen that the size of a memory address is <strong>limited</strong> by the size of registers.</p>
<p>A 32-bit system can only handle addresses that are composed of 32 bits. Each bit is composed either of a 0 or a 1, which makes <span class="math inline">2^{32}\approx4</span> billion possibilities.</p>
<ul>
<li><p>With 4 billion addresses, how many bytes can we address?</p>
<ul>
<li><p>=&gt; 4 billion bytes !</p></li>
<li><p>We can convert this quantity into Gigabytes.</p>
<ul>
<li><p>1 Gigabyte = 1 billion bytes</p></li>
<li><p>So, 4 billion bytes = 4 Gigabytes</p></li>
</ul></li>
</ul></li>
</ul>
<p>With a 32 bits systems, 4 Gigabytes of memory can be accessed by the system. Installing more than 4 GB of RAM on a 32 bits system is useless.</p>
<ul>
<li><p>On 64 bits systems, the number of addresses is much larger; hence the amount of memory addressable is bigger: theoretically 16 Exabytes (1 Exabyte =<span class="math inline">1.073.741.824</span> Gigabytes).</p>
<ul>
<li>As a consequence, 16 Exabyte is theoretically the maximum amount of RAM addressable on a 64 bits computer.</li>
</ul></li>
</ul>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is a bit?</p></li>
<li><p>What is a byte?</p></li>
<li><p>What is an encoder?</p></li>
<li><p>What is the maximum amount of RAM that can be addressable by a 32 bit System?</p></li>
<li><p>What is the decimal version of <span class="math inline">(001)_{binary}</span>?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is a bit?</p>
<ol type="1">
<li><p>This is the abbreviation of Binary Digit.</p></li>
<li><p>It’s either a 0 or a 1</p></li>
</ol></li>
<li><p>What is a byte?</p>
<ol type="1">
<li>A byte is 8 bits</li>
</ol></li>
<li><p>What is an encoder?</p>
<ol type="1">
<li><p>An encoder takes input in a given format and transforms it into another form.</p></li>
<li><p>The process is reversible.</p></li>
</ol></li>
<li><p>What is the maximum amount of RAM that can be addressable by a 32 bit System?</p>
<ol type="1">
<li>4 GB</li>
</ol></li>
<li><p>What is the decimal version of <span class="math inline">(001)_{binary}</span>?</p>
<ol type="1">
<li>1</li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Numerals represent numbers</p></li>
<li><p>Each numeration system has its way to represent numbers</p>
<ul>
<li>100 can be a number written in binary and decimal. It does not represent the same number/quantity!</li>
</ul></li>
<li><p>At the physical level, data is stored in memory using binary digits (0 and 1)</p></li>
<li><p>A bit is one binary digit</p></li>
<li><p>A byte is eight bits</p></li>
<li><p>A 64-bit system can address far more memory than a 32-bit system.</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>https://www.encyclopedia.com/social-sciences-and-law/economics-business-and-labor/businesses-and-occupations/numeration-systems<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap6BinaryAndDecimal"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap5FirstGoApplication'}">
									<p><u><small>Previous</small></u></p>
									<p><small>First Go Application</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap7HexadecimalOctalAsciiUtf8UnicodeRunes'}">
									<p><u><small>Next</small></u></p>
									<p><small>Hexadecimal, octal, ASCII, UTF8, Unicode, Runes</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Binary and Decimal - Practical Go Lessons"
const description = "Data written using the binary system will be written using “0” and “1”. For instance, 101010 is written using the binary encoding system.Data written using the decimal system will be written using 0, 1, 2, 3, 4, 5, 6, 7, 8, 9."

export default {
  name: "Chap6BinaryAndDecimal",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
